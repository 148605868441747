import styled from 'styled-components';
import { colors } from '../../../styles/colors';

export const Styles = styled.div`
  .course-filter {
    background: ${colors.bg2};
    padding: 62px 0 70px;

    .sec-title {
      h4 {
        color: ${colors.black1};
        line-height: 35px;
        font-weight: 600;
        max-width: 550px;
        margin: auto;
        margin-bottom: 42px;

        @media (max-width: 575px) {
          margin-bottom: 15px;
          font-size: 20px;
        }
      }
    }

    .filter-btns {
      ul.filter-btn-list {
        background-color: #ffffff;
        display: inline-block;
        border-radius: 5px;
        box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
        margin-bottom: 52px;

        li {
          font-size: 14px;
          color: ${colors.black2};
          font-weight: 500;
          padding: 10px 20px;
          cursor: pointer;
          border-radius: 5px;

          @media (max-width: 767px) {
            font-size: 13px;
            padding: 8px 12px;
          }
        }

        li.active {
          background: ${colors.grBg};
          color: #ffffff;
        }

        @media (max-width: 575px) {
          margin-bottom: 35px;
        }
      }
    }

    .filter-items {
      .course-item {
        transition: all 0.2s ease;
        margin-bottom: 30px;

        &:hover {
          box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
        }
      }
    }

    .viewall-btn {
      a {
        font-size: 15px;
        color: #fff;
        background: ${colors.grBg};
        display: inline-block;
        width: 200px;
        height: 48px;
        text-transform: uppercase;
        font-weight: 500;
        text-align: center;
        padding: 14px;
        border-radius: 5px;
        margin-top: 20px;

        &:hover {
          background: ${colors.grBg2};
        }

        @media (max-width: 575px) {
          font-size: 13px;
          width: 170px;
          height: 42px;
          padding: 12px;
          margin-top: 10px;
        }
      }
    }

    @media (max-width: 767px) {
      padding: 25px 0 40px;
    }
  }
`;
