import { Container, Row, Col } from 'react-bootstrap';
import { Courses } from '../../../types/schema';
import CourseCard from '../CourseCard';
import { Styles } from './styles';

export type HomeCoursesProps = {
  courses: Courses[];
};

const HomeCourses: React.FC<HomeCoursesProps> = ({ courses }) => {
  return (
    <Styles>
      <section className="course-filter">
        <Container>
          <Row>
            <Col md="12">
              <div className="sec-title text-center">
                <h4>Explore nossos cursos</h4>
              </div>
            </Col>
            <Col md="12">
              <Row className="filter-items">
                {courses.map((course) => (
                  <CourseCard key={course.id} course={course} colLg="4" colMd="6" />
                ))}
              </Row>
            </Col>
            {/* {courses.length > 0 && (
              <Col md="12" className="text-center">
                <div className="viewall-btn">
                  <Link href={'/cursos'}>Ver todos</Link>
                </div>
              </Col>
            )} */}
          </Row>
        </Container>
      </section>
    </Styles>
  );
};

export default HomeCourses;
