import Link from 'next/link';
import React from 'react';
import { buildUrl } from 'cloudinary-build-url';

//import { FaChair, FaClock, FaMapMarker } from 'react-icons/fa';
import { Courses } from '../../../types/schema';
import { StyledCol } from './styles';
//import { format, parseISO } from 'date-fns';
import InstructorPreview from '../InstructorPreview';

export type CourseCardProps = {
  course: Courses;
  colLg?: string;
  colMd?: string;
  colSm?: string;
};

const CourseCard: React.FC<CourseCardProps> = ({
  course,
  colLg = '4',
  colMd = '6',
  colSm = '12',
}) => {
  const url = course.image?.url || '';
  const imageUrl = buildUrl(url, {
    cloud: {
      cloudName: 'appmasters-io',
    },
    transformations: {
      resize: {
        type: 'fit',
        width: 400,
        height: 300,
        quality: 20,
      },
    },
  });
  return (
    <StyledCol lg={colLg} md={colMd} sm={colSm}>
      <div className="course-item">
        <Link href={`/cursos/${course.slug}`}>
          <div className="course-image" style={{ backgroundImage: `url(${imageUrl})` }}>
            {course.preSubscription ? (
              <div className="pre-subscription">
                <p>Pré-inscrição</p>
              </div>
            ) : null}
            {<InstructorPreview authors={course.authors} />}
            {/* <div className="course-price">
                {course.subscriptionType === 'free' ? (
                  <p>{CoursesSubscriptionTypeMap[course.subscriptionType]}</p>
                ) : (
                  <p>{course.price === 0 ? 'Gratuito' : `R& ${course.price}`}</p>
                )}
              </div> */}
          </div>
        </Link>
        <div className="course-content">
          <h6 className="heading">
            <Link href={`/cursos/${course.slug}`}>{course.title}</Link>
          </h6>
          <p style={{ margin: 0 }} className="desc border-0">
            {course.shortDescription}
          </p>
          {/* <div className="course-face d-flex justify-content-between">
            {course.startDate && (
              <div className="duration">
                <p>
                  <i>
                    <FaClock />
                  </i>
                  {format(parseISO(course.startDate), 'dd/MM/yyyy')}
                </p>
              </div>
            )}

            <div className="location">
              <p>
                <i>
                  <FaMapMarker />
                </i>
                {course.remote ? 'Remoto' : 'Presencial'}
              </p>
            </div>

            <div className="student">
              <p>
                <i>
                  <FaChair />
                </i>
                {course.maxStudents}
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </StyledCol>
  );
};

export default CourseCard;
