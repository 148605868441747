import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { colors } from '../../../styles/colors';

export const StyledCol = styled(Col)`
  /* Course Grid */

  .course-item {
    border: 1px solid ${colors.border1};
    border-radius: 5px;
    transition: all 0.2s ease;
    overflow: hidden;
    margin-bottom: 30px;

    .course-image {
      width: 100%;
      height: 240px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 5px 5px 0 0;
      position: relative;

      .pre-subscription {
        p {
          font-size: 16px;
          color: #ffffff;
          background: ${colors.blue};
          position: absolute;
          top: 20px;
          right: 20px;
          padding: 4px 8px;
          font-weight: 500;
          border-radius: 5px;
        }
      }

      .course-price {
        p {
          font-size: 16px;
          color: #ffffff;
          background: ${colors.bg1};
          position: absolute;
          right: 20px;
          bottom: 20px;
          padding: 8px 10px;
          font-weight: 500;
          border-radius: 5px;
        }
      }

      @media (max-width: 767px) {
        height: 185px;
      }
    }

    .course-content {
      background: #fff;
      padding: 20px 25px;

      h6.heading {
        a {
          color: ${colors.black1};
          font-weight: 600;
          display: inline-block;
          margin-bottom: 12px;

          &:hover {
            color: ${colors.blue2};
          }
        }
      }

      p.desc {
        font-size: 14px;
        color: ${colors.text3};
        line-height: 25px;
        border-bottom: 1px solid ${colors.border1};
        padding-bottom: 5px;
        margin-bottom: 12px;

        @media (min-width: 767px) {
          height: 125px;
          overflow: hidden;
          text-overflow: ellipsis;

          display: -webkit-box;
          -webkit-line-clamp: 5; /* number of lines to show */
          -webkit-box-orient: vertical;
        }
      }

      .course-face {
        .duration,
        .student,
        .location {
          p {
            font-size: 13px;
            color: ${colors.text3};

            i {
              font-size: 12px;
              color: ${colors.blue2};
              vertical-align: text-bottom;
              margin-right: 3px;
            }
          }
        }

        .rating {
          ul {
            li {
              margin-right: 0;

              i {
                font-size: 14px;
                color: ${colors.yellow};
              }

              &:last-child {
                font-size: 13px;
                color: ${colors.text3};
              }
            }
          }
        }
      }
    }

    &:hover {
      box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
    }
  }
`;
