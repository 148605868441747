import { LocalBusinessJsonLd, NextSeo, SocialProfileJsonLd } from 'next-seo';
import React from 'react';
import { GetStaticProps } from 'next';
import { initializeApollo } from '../utils/apollo';

import { GET_COURSES } from '../queries/courses';
import { Courses } from '../types/schema';

import HeroImage from '../components/elements/HeroImage';
import HomeCourses from '../components/ui/HomeCourses';
import { flatten } from '../utils/object';
import rollbar from '../utils/rollbar';

//import NewsLetterForm from '../components/elements/NewsletterForm';

const Home: React.FC<{ courses: Courses[] }> = ({ courses }) => {
  const siteUrl = process.env.NEXT_PUBLIC_SITE_URL || 'http://localhost:3000';

  const profile = {
    type: 'Organization',
    name: 'App Masters',
    url: 'https://appmasters.io',
    sameAs: [
      'https://www.facebook.com/appmasters.io/',
      'https://www.instagram.com/appmasters.io/',
      'https://www.linkedin.com/company/appmasters.io/?originalSubdomain=br',
      'https://appmasters.io/en/',
    ],
  };

  const business = {
    type: 'Store',
    id: 'http://appmasters.io',
    name: 'App Masters',
    description:
      'Empresa de desenvolvimento de softwares, aplicações web, mobile e cloud, em Juiz de Fora, MG.',
    url: siteUrl,
    telephone: '+5532988735683',
    address: {
      streetAddress: 'Avenida Barão do Rio Branco, 3480',
      addressLocality: 'Juiz de Fora',
      addressRegion: 'MG',
      postalCode: '36025-020',
      addressCountry: 'BR',
    },
  };
  return (
    <React.Fragment>
      <NextSeo canonical={siteUrl} />
      <SocialProfileJsonLd {...profile} />
      <LocalBusinessJsonLd {...business} />
      <HeroImage />
      <HomeCourses courses={courses} />
      {/* <NewsLetterForm /> */}
    </React.Fragment>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const apolloClient = initializeApollo();
  try {
    const { data } = await apolloClient.query({
      query: GET_COURSES,
    });

    const newData = flatten(data);

    return {
      props: {
        courses: newData.courses,
      },
      revalidate: 60,
    };
  } catch (error) {
    rollbar.error('Error fetching courses - Home', { error });
    return {
      notFound: true,
    };
  }
};

export default Home;
