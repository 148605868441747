import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from './styles';
import { FaGraduationCap, FaWpforms, FaUsers } from 'react-icons/fa';
import { imageConstraints } from '../../../utils/constraints';

export default function HeroImage() {
  return (
    <Styles>
      {/* Hero Image */}
      <section
        className="hero-image-area"
        style={{
          backgroundImage: `url(${imageConstraints['Home-Hero']})`,
        }}
      >
        <div className="hero-table">
          <div className="hero-tablecell">
            <Container>
              <Row>
                <Col md="12">
                  <div className="hero-box text-center">
                    <h1>Cursos de programação para todos os níveis</h1>
                    <h3>Desde os primeiros passos até os tópicos mais avançados</h3>
                    <div className="info-list">
                      <span>
                        <FaGraduationCap />
                        Instrutores do mercado
                      </span>
                      <span>
                        <FaWpforms />
                        Conteúdo atualizado
                      </span>
                      <span>
                        <FaUsers />
                        Pequenas turmas
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </Styles>
  );
}
