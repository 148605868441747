import styled from 'styled-components';
import { colors } from '../../../styles/colors';

export const Styles = styled.div`
  .author-img {
    position: absolute;
    left: 20px;
    bottom: 20px;

    img {
      max-width: 40px;
      border-radius: 50%;
    }
    .img:hover {
      z-index: 10;
    }
    .title {
      background: #ffffff;
      padding: 3px 8px;
      border-radius: 5px;
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      p {
        font-size: 12px;
        color: ${colors.black1};
        font-weight: 500;
        margin-bottom: -4px;
      }

      span {
        font-size: 11px;
        color: ${colors.text3};
        font-weight: 500;
      }
    }
  }
`;
