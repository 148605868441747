import React from 'react';
import Image from 'next/image';
import { Styles } from './styles';

import { Authors, Maybe } from '../../../types/schema';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export type InstructorPreviewProps = {
  authors?: Maybe<Authors>[] | null;
};

const InstructorPreview: React.FC<InstructorPreviewProps> = ({ authors }) => {
  return authors && authors.length > 0 ? (
    <Styles>
      <div className="author-img d-flex">
        {authors.length === 1 ? (
          <>
            <OverlayTrigger
              placement="top"
              delay={{ show: 0, hide: 0 }}
              overlay={(props) => (
                <Tooltip id="title" className="title" {...props}>
                  <p>{authors[0]?.name}</p>
                  <span>Instrutor</span>
                </Tooltip>
              )}
            >
              <Image
                className="img"
                src={authors[0]?.image?.url as string}
                width={40}
                height={40}
                alt="Logo"
              />
            </OverlayTrigger>
          </>
        ) : (
          <>
            {authors.map((a, index) => {
              return (
                <OverlayTrigger
                  key={index}
                  placement="top"
                  delay={{ show: 0, hide: 0 }}
                  overlay={(props) => (
                    <Tooltip id="title" className="title" {...props}>
                      <p>{a?.name}</p>
                      <span>Instrutor</span>
                    </Tooltip>
                  )}
                >
                  <div className="img" style={{ marginLeft: -index * 25 }}>
                    <Image src={a?.image?.url as string} width={40} height={40} alt="Logo" />
                  </div>
                </OverlayTrigger>
              );
            })}
          </>
        )}
      </div>
    </Styles>
  ) : null;
};

export default InstructorPreview;
